import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import {
  acceptedLanguages,
  getNavigatorLanguage
} from '../utils/languages.utils'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguage: BehaviorSubject<string> = new BehaviorSubject('en')
  constructor(private translate: TranslateService) {
    translate.addLangs(acceptedLanguages)
  }

  public async setDefaultLanguage() {
    const savedLanguage = await localStorage.getItem('lang')
    const navLanguage = navigator.language.substring(0, 2)
    this.setLanguage(savedLanguage || getNavigatorLanguage())
  }

  public async setLanguage(language: any) {
    this.currentLanguage.next(language)
    await this.translate.setDefaultLang(this.currentLanguage.getValue())
    await this.translate.use(this.currentLanguage.getValue())
    await localStorage.setItem('lang', this.currentLanguage.getValue())
  }

  public getLanguage(): string {
    return this.currentLanguage.getValue()
  }

  public getLongFormatLang() {
    return (
      this.currentLanguage.getValue() +
      '-' +
      this.currentLanguage.getValue().toUpperCase()
    )
  }
}
