import { registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeEn from '@angular/common/locales/en'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { from } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomeComponent } from './pages/home/home.component'
import { LanguageService } from './services/language.service'
import { DropdownComponent } from './shared/dropdown/dropdown.component'
import { PopupComponent } from './shared/popup/popup.component'
import { FormsModule } from '@angular/forms'
import { DiscoveryService } from './services/discovery.service'
import { CodeRequestComponent } from './shared/code-request/code-request.component'
import { LoadingComponent } from './shared/loading/loading.component'

registerLocaleData(localeFr)
registerLocaleData(localeEn)

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`))
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DropdownComponent,
    PopupComponent,
    CodeRequestComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    })
  ],
  providers: [
    DiscoveryService,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: any) => {
        return languageService.getLanguage()
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
