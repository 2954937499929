import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  forwardRef,
  OnDestroy
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ],
  animations: [
    trigger('collapse', [
      state(
        'visible',
        style({
          'max-height': '200px',
          overflow: 'auto'
        })
      ),
      state(
        'hidden',
        style({
          'max-height': 0,
          visibility: 'hidden',
          overflow: 'hidden'
        })
      ),
      transition('visible => hidden', [animate('200ms  ease-in')]),
      transition('hidden => visible', [animate('200ms ease-in')])
    ]),
    trigger('rotate', [
      state(
        'visible',
        style({
          transform: 'rotateZ(180deg)'
        })
      ),
      state(
        'hidden',
        style({
          transform: 'rotateZ(0deg)'
        })
      ),
      transition('hidden => visible', animate('200ms  ease-in')),
      transition('visible => hidden', animate('200ms  ease-in'))
    ])
  ]
})
export class DropdownComponent implements ControlValueAccessor, OnDestroy {
  public optionsDisplayed = false
  public selected = null
  public optionsList: any[] = []
  private onChange: any
  public placeholderValue = ''
  private translation$!: Subscription
  public windowWidth

  @Input()
  set placeholder(value: any) {
    if (value) {
      this.placeholderValue = value
    } else {
      this.translation$ = this.translate
        .get('Global.SelectOption')
        .subscribe((res: string) => {
          this.placeholderValue = res
        })
    }
  }
  @Input() default = null
  @Output() selectOption = new EventEmitter()

  @HostBinding('style.width') widthHost = '100%'
  @HostBinding('style.maxWidth') maxWidth = '300px'
  @HostBinding('style.minWidth') minWidth = '70px'
  @ViewChild('options', { static: false }) optionsElement!: ElementRef

  @HostListener('document:click', ['$event'])
  onClick(element: any) {
    const displayed = this.optionsDisplayed
    if (!this.eRef.nativeElement.contains(element.target) && displayed) {
      this.displayOptions()
    }
  }

  @Input()
  set width(value: string) {
    this.widthHost = value
  }

  @Input()
  set options(value: any[]) {
    this.optionsList = value
  }

  constructor(
    private eRef: ElementRef,
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.windowWidth = window.innerWidth
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {}
  setDisabledState(isDisabled: boolean): void {}
  writeValue(value: any): void {
    this.selected = value
  }

  public displayOptions() {
    this.optionsDisplayed = !this.optionsDisplayed
    this.cd.detectChanges()
  }

  public clickOption(option: any) {
    if (this.onChange) {
      this.onChange(option)
    }
    this.selected = option
    this.displayOptions()
    this.selectOptionInList(option)
    this.selectOption.emit(option)
  }

  public selectOptionInList(option: any) {
    this.optionsList.map(opt => {
      opt.selected = false
    })
    this.optionsList[this.optionsList.indexOf(option)].selected = true
  }

  ngOnDestroy() {
    if (this.translation$) {
      this.translation$.unsubscribe()
    }
  }
}
