import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core'
import { DiscoveryService } from '../../services/discovery.service'

@Component({
  selector: 'app-code-request',
  templateUrl: './code-request.component.html',
  styleUrls: ['./code-request.component.scss']
})
export class CodeRequestComponent implements AfterViewInit {
  public windowWidth
  public clientCode = '8001'
  public email = ''
  public errorType!: string | null
  public errorOpened = false
  public apiErrorMessage = ''
  public loading = false
  public emailPattern =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  public formError!: string | null
  public successPopup = false
  public errorPopup = false

  @Input()
  set initClientCode(value: any) {
    if (value) {
      this.clientCode = value
    }
  }
  @Output() closePopup = new EventEmitter()
  @Output() clientCodeSuccess = new EventEmitter()

  constructor(private discovery: DiscoveryService) {
    this.windowWidth = window.innerWidth
  }

  ngAfterViewInit(): void {
    document.getElementById('email')?.focus()
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(): boolean {
    if (this.clientCode.length === 4 && !this.email) {
      document.getElementById('email')?.focus()
    }
    return true
  }

  public keyPress(event: any, numeric = true): boolean {
    this.formError = null
    if (numeric) {
      const keyCodeName = 'keyCode'
      const key = event[keyCodeName]
      return key >= 48 && key <= 57
    }
    return true
  }

  public closeModal(): void {
    this.closePopup.emit()
  }

  public closeErrorModal(): void {
    this.errorPopup = false
  }

  public clearClientCode(): void {
    this.clientCode = ''
  }

  public clearEmail(): void {
    this.email = ''
  }

  public async requestCode(): Promise<void> {
    if (!this.clientCode) {
      this.formError = '1-1.Authentication.InstitutionCodeRequired'
      return
    }

    if (this.clientCode.length !== 4) {
      this.formError = '1-1.Authentication.InstitutionCodeFormat'
      return
    }

    if (!this.email || this.email.length === 0) {
      this.formError = '1-1.Authentication.EmailRequired'
      return
    }

    if (!this.email.match(this.emailPattern)) {
      this.formError = '1-1.Authentication.EmailFormat'
      return
    }

    this.formError = null
    this.loading = true
    try {
      const services = (await this.discovery.getService(this.clientCode)) as any
      await this.discovery.codeRequest(
        services.default.url,
        this.clientCode,
        this.email,
        2
      )
      this.successPopup = true
      this.clientCodeSuccess.emit(this.clientCode)
      this.loading = false
    } catch (error) {
      this.launchError(error)
      this.errorPopup = true
      this.loading = false
    }
  }

  private launchError(apiError: any): void {
    try {
      switch (apiError.status) {
        case 0:
          this.errorType = 'Connection'
          this.errorOpened = true
          break
        case 400:
          this.errorType = 'ApiError'
          this.apiErrorMessage = this.getApiError(apiError)
          this.errorOpened = true
          break
        case 404:
        case 500:
          this.errorType = 'Global'
          this.errorOpened = true
          break
        default:
          this.errorType = 'Unknown'
          this.errorOpened = true
          break
      }
    } catch (error) {
      this.errorType = 'Unknown'
      this.errorOpened = true
    }
  }

  private getApiError(apiError: any): string {
    try {
      return apiError.error.messages.errors.body[0]
    } catch (error) {
      return ''
    }
  }
}
