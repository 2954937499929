import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, HostListener } from '@angular/core'
import { LanguageService } from '../../services/language.service'
import { getRGPDLink, languages } from '../../utils/languages.utils'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('enterTransition', [
      state(
        'void',
        style({
          opacity: 0
        })
      ),
      transition('void <=> *', animate('500ms ease-in'))
    ]),
    trigger('boxTransitionOne', [
      transition(':enter', [
        style({ transform: 'scale(0.9)', opacity: 0 }),
        animate('0.5s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'scale(1)', opacity: 1 }))
      ])
    ]),
    trigger('boxTransitionTwo', [
      transition(':enter', [
        style({ transform: 'scale(0.9)', opacity: 0 }),
        animate('0.8s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'scale(1)', opacity: 1 }))
      ])
    ]),
    trigger('boxTransitionThree', [
      transition(':enter', [
        style({ transform: 'scale(0.9)', opacity: 0 }),
        animate('1.1s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'scale(1)', opacity: 1 }))
      ])
    ])
  ]
})
export class HomeComponent {
  public languages = languages
  public defaultLanguage: any = null
  public appleModalOpened = false
  public appModalOpened = false
  public windowWidth = 900
  public downloadUrl = ''
  public getIdsModalOpened = false
  public smallScreen = true

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.smallScreen = window.innerWidth < 1000
  }

  constructor(private languageService: LanguageService) {
    this.downloadUrl = 'https://storage.gra.cloud.ovh.net/v1/AUTH_4aaeaa272e4247bb9ed3ca297ceb7fe7/cdn/azure_app/michelin/michelin.apk'
    this.windowWidth = window.innerWidth
    this.getLanguages()
  }

  private async getLanguages(): Promise<void> {
    await this.languageService.setDefaultLanguage()
    const storedLanguage = this.languageService.getLanguage()
    this.defaultLanguage = this.languages.find(
      lang => lang.id === storedLanguage
    )
  }

  public selectLanguage(language: any) {
    this.languageService.setLanguage(language.id)
  }

  public goWebsite() {
    window.open(
      `https://sowesign.com/${
        this.languageService.getLanguage() === 'en' ? 'en' : ''
      }`,
      '_blank'
    )
  }

  public goWebapp() {
    window.open('https://michelin.sowesign.com/webapp', '_blank')
  }

  public goManager() {
    window.open('https://michelin.sowesign.com/portal', '_blank')
  }

  public openAppleModal() {
    this.appleModalOpened = true
  }

  public closeAppleModal() {
    this.appleModalOpened = false
  }

  public openAppModal() {
    this.appModalOpened = true
  }

  public closeAppModal() {
    this.appModalOpened = false
  }
  public openGetIdsModal() {
    this.getIdsModalOpened = true
  }

  public closeGetIdsModal() {
    this.getIdsModalOpened = false
  }

  public goRGPDLink(linkType: string) {
    window.open(
      getRGPDLink(this.languageService.getLanguage(), linkType),
      '_blank'
    )
  }
}
