import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs/internal/Subscription'

@Component({
  selector: 'popup-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  public title = ''
  public windowWidth

  constructor() {
    this.windowWidth = window.innerWidth
  }
}
