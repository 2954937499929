import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DiscoveryService {
  constructor(private http: HttpClient) {}

  public getService(userCode: string) {
    return lastValueFrom(
      this.http.get(`https://app.sowesign.com/api/portal/services/${userCode}`)
    )
  }

  public codeRequest(
    serviceUrl: string,
    customerCode: string,
    email: string,
    type: number
  ) {
    return lastValueFrom(
      this.http.post(
        `${serviceUrl}/api/portal/authentication/identification/sendEmail`,
        { client: customerCode, mail: email, type, origin: 'apave' },
        {
          observe: 'response',
          headers: new HttpHeaders().set('Accept-Language', 'fr')
        }
      )
    )
  }
}
